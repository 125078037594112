import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CommonDropdownDto, CommonStringBasedDropdownDto } from '@api-open';
import { CONFIG } from '@config';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  baseUrl = `${CONFIG.apiUrl}common`;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private auditLogs: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private azureQuotas: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private cloudTypes: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private projectStatuses: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private serverStatuses: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private userRoles: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private proxmoxRoles: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private prometheusTypes: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private securityGroupRules: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private rebootOptions: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private availability: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private slackTypes: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private requestLogs: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private showbackKinds: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private alertTypes: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private reminderTypes: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private cronPeriods: CommonStringBasedDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private alertingIntegrationTypes: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private standaloneVmStatuses: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private googleImageTypes: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private validityPeriods: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private openstackContinents: CommonDropdownDto[] = undefined!;
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  private retentionPeriods: CommonStringBasedDropdownDto[] = undefined!;

  constructor(private http: HttpClient) {}

  getEnumValues() {
    return this.http.get<any>(`${this.baseUrl}/enumvalues`);
  }

  setEnumValues(enumValues: any) {
    this.auditLogs = enumValues.auditLogs;
    this.azureQuotas = enumValues.azureQuotas;
    this.cloudTypes = enumValues.cloudTypes;
    this.projectStatuses = enumValues.projectStatuses;
    this.serverStatuses = enumValues.serverStatuses;
    this.userRoles = enumValues.userRoles;
    this.proxmoxRoles = enumValues.proxmoxRoles;
    this.prometheusTypes = enumValues.prometheusTypes;
    this.securityGroupRules = enumValues.securityGroupRules;
    this.rebootOptions = enumValues.rebootOptions;
    this.availability = enumValues.availability;
    this.slackTypes = enumValues.slackTypes;
    this.requestLogs = enumValues.requestLogs;
    this.showbackKinds = enumValues.showbackKinds;
    this.alertTypes = enumValues.alertTypes;
    this.reminderTypes = enumValues.reminderTypes;
    this.cronPeriods = enumValues.cronPeriods;
    this.alertingIntegrationTypes = enumValues.alertingIntegrationTypes;
    this.standaloneVmStatuses = enumValues.standaloneVmStatuses;
    this.googleImageTypes = enumValues.googleImageTypes;
    this.validityPeriods = enumValues.validityPeriods;
    this.openstackContinents = enumValues.openstackContinents;
    this.retentionPeriods = enumValues.retentionPeriods;
  }

  getAuditLogs() {
    return this.auditLogs;
  }

  getAzureQuotas() {
    return this.azureQuotas;
  }

  getCloudTypes() {
    return this.cloudTypes;
  }

  getProjectStatuses() {
    return this.projectStatuses;
  }

  getPrometheusTypes() {
    return this.prometheusTypes;
  }

  getServerStatuses() {
    return this.serverStatuses;
  }

  getUserRoles() {
    return this.userRoles;
  }

  getProxmoxRoles() {
    return this.proxmoxRoles;
  }

  getSecurityGroupRules() {
    return this.securityGroupRules;
  }

  getRebootOptions() {
    return this.rebootOptions;
  }

  getAvailability() {
    return this.availability;
  }

  getSlackTypes() {
    return this.slackTypes;
  }

  getRequestLogs() {
    return this.requestLogs;
  }

  getShowbackKinds() {
    return this.showbackKinds;
  }

  getAlertTypes() {
    return this.alertTypes;
  }

  getAlertingReminderTypes() {
    return this.reminderTypes;
  }

  getCronPeriods() {
    return this.cronPeriods;
  }

  getIntegrationTypesForAlertingProfile() {
    return this.alertingIntegrationTypes;
  }

  getStandaloneVmStatuses() {
    return this.standaloneVmStatuses;
  }

  getGoogleImageTypes() {
    return this.googleImageTypes;
  }

  getValidityPeriods() {
    return this.validityPeriods;
  }

  getOpenStackContinents() {
    return this.openstackContinents;
  }

  getRetentionPeriods() {
    return this.retentionPeriods;
  }
}
