import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CONFIG } from '@config';

export interface AdminServicesStatus {
  entries: { [key: string]: { status: string } };
}

@Injectable({
  providedIn: 'root',
})
export class CustomAPIServices {
  private readonly baseUrl: string = CONFIG.host;

  constructor(private http: HttpClient) {}

  getAdminControlPanelServices(): Observable<AdminServicesStatus> {
    const localVarPath = 'health';
    return this.http.get<AdminServicesStatus>(`${this.baseUrl}${localVarPath}`);
  }
}
