import { DOCUMENT } from '@angular/common';
import { inject, Inject, Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { productFruits } from 'product-fruits';
import { filter, takeUntil } from 'rxjs/operators';

import { UserForListDto } from '@api-open';
import { CONFIG } from '@config';
import { AuthService } from '@shared/services/auth.service';
import { UserDataService } from '@shared/services/data-stores/user-data.service';

interface ProductFruitsInstance {
  services?: {
    destroy: () => void;
  };
  api?: {
    button: {
      open: () => void;
      close: () => void;
      listen: (t: string, e: () => void) => void;
    };
  };
}

declare global {
  interface Window {
    productFruits?: ProductFruitsInstance;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductFruitsService {
  readonly isOpen = signal(false);

  private readonly productFruitsWorkspaceCode = CONFIG.productFruitsWorkspaceCode;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private authService: AuthService,
  ) {
    this.trackInit();
    this.trackLogout();
  }

  private get productFruitInstance(): ProductFruitsInstance | undefined {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    return this.document.defaultView!.productFruits;
  }

  open(): void {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.productFruitInstance!.api!.button.open();
    const elem = document.querySelector('.productfruits--container');
    if (elem) {
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      const productfruitsModal = elem.shadowRoot!.querySelector('.productfruits--lifering-modal');
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      const productfruitsRoot = elem.shadowRoot!.querySelector('.productfruits--container-root');

      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      productfruitsRoot!.setAttribute('style', 'opacity:0');
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      productfruitsModal!.classList.remove('productfruits--animation-fadein');

      setTimeout(() => {
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        productfruitsModal!.setAttribute('style', 'top:unset;left:unset;bottom:25px;right:25px');
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        productfruitsRoot!.setAttribute('style', 'opacity:1');

        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        productfruitsModal!.classList.add('productfruits--animation-fadein');
      }, 200);
    }
  }

  close(): void {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.productFruitInstance!.api!.button.close();
  }

  toggleOpenClose(): void {
    if (this.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  private trackInit(): void {
    inject(UserDataService)
      .getCurrentUser()
      .subscribe((user) => {
        if (user) {
          // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
          this.initProductFruits(user.data!);
        }
      });
  }

  private initProductFruits({ username, email, role, owner }: UserForListDto): void {
    productFruits.init(
      this.productFruitsWorkspaceCode,
      'en',
      {
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        username: username!,
        email: email!,
        role,
        props: {
          owner: owner + '',
        },
      },
      {
        disableLocationChangeDetection: true,
      },
    );

    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.document.defaultView!.addEventListener('productfruits_ready', () => {
      this.trackClickEvents();
      this.trackLocationChange();
    });
  }

  private trackClickEvents(): void {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.productFruitInstance!.api!.button.listen('closed', () => {
      this.isOpen.set(false);
    });

    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.productFruitInstance!.api!.button.listen('opened', () => {
      this.isOpen.set(true);
    });
  }

  private trackLocationChange(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.authService.logoutEvent$),
      )
      .subscribe(() => this.triggerLocationChange());
  }

  private triggerLocationChange(): void {
    productFruits.safeExec(($productFruits) => {
      $productFruits.push(['pageChanged']);
    });
  }

  private trackLogout(): void {
    this.authService.logoutEvent$.subscribe(() => {
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      this.productFruitInstance!.services?.destroy();
    });
  }
}
