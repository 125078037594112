import { Injectable } from '@angular/core';

import { CONFIG } from '@config';

declare const gtag: (
  command: 'config' | 'get' | 'set' | 'event',
  commandParams: string,
  additionalConfigInfo: Record<string, unknown>,
) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  eventEmitter(
    eventName: string,
    extraData?: { eventCategory?: string; eventAction?: string; eventLabel?: string; eventValue?: number },
  ) {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    if (this.isproductionEnvironment!) {
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      gtag('event', eventName, extraData!);
    }
  }

  private isproductionEnvironment() {
    return CONFIG.environment === 'prod';
  }
}
