import { Injectable } from '@angular/core';

import { UserDetails, UserForListDto, UserRole } from '@api-open';
import { UserSyntheticRole } from '@shared/enums/synthetic-roles.enum';

import { UserDataService } from './data-stores/user-data.service';

export type RolesList = {
  user: Partial<UserDetails['data']>;
  userGeneral?: Partial<Omit<UserDetails, 'data'>>;
};

export enum UserActionsId {
  lockedUser = 'lockedUser',
  managerNotOwnerLocked = 'managerNotOwnerLocked',
  managerOwnerLocked = 'managerOwnerLocked',
}

export type UserActions = {
  user: Partial<UserDetails['data']>;
  metaData: {
    url: string;
    id: UserActionsId;
  };
};

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private usersAction: UserActions[] = [
    {
      user: { role: UserRole.user, isLocked: true },
      metaData: { url: 'informing-user', id: UserActionsId.lockedUser },
    },
    {
      user: { role: UserRole.manager, owner: false, isLocked: true },
      metaData: { url: 'informing-user', id: UserActionsId.managerNotOwnerLocked },
    },
    {
      user: { role: UserRole.manager, owner: true, isLocked: true },
      metaData: { url: 'subscription', id: UserActionsId.managerOwnerLocked },
    },
  ];

  constructor(private userDataService: UserDataService) {}
  check(...roles: UserRole[]): boolean {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    const userRole = this.userDataService.currentUser$.getValue().data!.role;
    return [...roles].some((role) => role === userRole);
  }

  checkRoleAndUser(rolesList: RolesList[]): { isEqual: boolean; currentUserData: UserDetails['data'] } {
    const currentUser = this.userDataService.currentUser$.getValue();

    const { data: currntUserData } = currentUser;

    const result = rolesList.some((role) => {
      let isUserEqual = false;
      let isUserGeneralEqual = true;

      if ('user' in role) {
        const { user } = role;
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        isUserEqual = Object.keys(user!).every((key) => {
          // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
          const isEqual = user![key] === currntUserData![key];
          return isEqual;
        });
      }

      if ('userGeneral' in role) {
        const { userGeneral } = role;
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        isUserGeneralEqual = Object.keys(userGeneral!).every((key) => {
          // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
          const isEqual = userGeneral![key] === currentUser[key];

          return isEqual;
        });
      }

      return isUserEqual && isUserGeneralEqual;
    });

    return { isEqual: result, currentUserData: currntUserData };
  }

  getActionForProhibited(user: UserDetails['data']): UserActions['metaData'] | null {
    const userRes = this.usersAction.find((userAction) =>
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      Object.keys(userAction.user!).every((key) => {
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        const isEqual = user![key] === userAction.user![key];
        return isEqual;
      }),
    );

    return userRes ? userRes.metaData : null;
  }

  getUserSyntheticRole(user: UserForListDto): UserSyntheticRole {
    switch (user.role) {
      case UserRole.admin:
        return UserSyntheticRole.admin;

      case UserRole.partner:
        return user.owner ? UserSyntheticRole.partnerOwner : UserSyntheticRole.partner;

      case UserRole.manager:
        return user.owner ? UserSyntheticRole.managerOwner : UserSyntheticRole.manager;

      case UserRole.user:
        return UserSyntheticRole.user;

      case UserRole.autoscaler:
      default:
        return UserSyntheticRole.autoscaler;
    }
  }

  getCurrentUserSyntheticRole(): UserSyntheticRole {
    const currentUser = this.userDataService.currentUser$.getValue()?.data;
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    return currentUser! && this.getUserSyntheticRole(currentUser);
  }
}
