import { inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { defer, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CONFIG } from '@config';
import { AuthService } from '@shared/services/auth.service';

export const HUB_CONNECTION_BUILDER = new InjectionToken<typeof HubConnectionBuilder>(
  'SignalR HubConnectionBuilder factory',
  {
    providedIn: 'root',
    factory: () => HubConnectionBuilder,
  },
);

@Injectable()
export class NotificationsLiveService implements OnDestroy {
  private readonly notificationsApiUrl = `${CONFIG.host}audit/notify`;

  private readonly connection: HubConnection;
  private readonly authService = inject(AuthService);

  constructor() {
    this.connection = new (inject(HUB_CONNECTION_BUILDER))()
      .withUrl(this.notificationsApiUrl, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => this.authService.getToken(),
      })
      .withAutomaticReconnect([2000, 4000, 8000])
      .build();
  }

  getDataStream<T>(methodName: string, ...args: (string | number)[]): Observable<T> {
    return defer(() => from(this.connect()).pipe(switchMap(() => this.createStream<T>(methodName, ...args))));
  }

  async disconnect(): Promise<void> {
    if (
      this.connection.state !== HubConnectionState.Disconnecting &&
      this.connection.state !== HubConnectionState.Disconnected
    ) {
      await this.connection.stop();
    }
  }

  async ngOnDestroy(): Promise<void> {
    await this.disconnect();
  }

  private connect(): Promise<void> {
    if (
      this.connection.state !== HubConnectionState.Connected &&
      this.connection.state !== HubConnectionState.Connecting
    ) {
      return this.connection.start();
    }
    return Promise.resolve();
  }

  private createStream<T>(methodName: string, ...args: (string | number)[]): Observable<T> {
    const eventName = `${methodName}-${args.join('-')}`;

    return new Observable<T>((subscriber) => {
      this.connection.on(eventName, (event: T) => subscriber.next(event));
      return () => this.connection.off(eventName);
    });
  }
}
