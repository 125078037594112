import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackButtonService {
  private route$ = new BehaviorSubject<string>('');

  setRoute(newRoute: string): void {
    this.route$.next(newRoute);
  }
}
