/**
 * Taikun - WebApi
 * This Api will be responsible for overall data distribution and authorization.
 *
 * Contact: noreply@taikun.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum UserRole {
    admin = 'Admin',
    manager = 'Manager',
    partner = 'Partner',
    user = 'User',
    autoscaler = 'Autoscaler'
}

