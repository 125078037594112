import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type KubernetesAlertType = 'firing' | 'silenced' | 'resolved' | 'all';

@Injectable({
  providedIn: 'root',
})
export class KubernetesService {
  reloadData$ = new BehaviorSubject(false);
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  selectedNamespace: string = undefined!;

  // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
  private currentKubernetesNamespace$ = new BehaviorSubject<string>(null!);

  setCurrentNamespace(namespace: string) {
    this.currentKubernetesNamespace$.next(namespace);
    this.selectedNamespace = namespace;
    this.reloadData$.next(true);
  }

  getCurrentNamespace() {
    return this.currentKubernetesNamespace$.asObservable();
  }
}
