import { inject, Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeOfDomElementService {
  private readonly ngZone = inject(NgZone);
  onResize(elem: any) {
    return this.ngZone.runOutsideAngular(
      () =>
        new Observable((observer: any) => {
          const ro = new ResizeObserver((entries) => {
            observer.next(entries);
          });

          ro.observe(elem);
          return () => {
            ro.unobserve(elem);
          };
        }),
    );
  }
}
