import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { OperationDto } from '@api-open';
import { CONFIG } from '@config';

import { NotificationsLiveService } from '../../layouts/main/action-bar/notifications/notifications-live.service';

@Injectable()
export class OperationService {
  baseUrl = `${CONFIG.apiUrl}notifications`;

  private operations$ = new BehaviorSubject<OperationDto[]>([]);

  private subscriptions = new Map<number, Subscription>([]);

  constructor(private notificationsLiveService: NotificationsLiveService) {}

  addOperationsListener(projectId: number) {
    this.subscriptions.set(
      projectId,
      this.notificationsLiveService.getDataStream<OperationDto>('operations', projectId).subscribe((operation) => {
        this.setOperations([...this.operations$.getValue(), operation]);
      }),
    );
  }

  removeOperationsListener(projectId: number) {
    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.subscriptions.get(projectId)!.unsubscribe();
    this.subscriptions.delete(projectId);
    this.setOperations([]);
  }

  getOperations(projectId?: number, getOnlyLatest?: true): Observable<OperationDto>;
  getOperations(projectId?: number, getOnlyLatest?: false): Observable<OperationDto[]>;
  getOperations(projectId?: number, getOnlyLatest = true): Observable<OperationDto | OperationDto[]> {
    return this.operations$.pipe(
      map((operations) => {
        if (projectId) {
          return operations.filter((operation) => operation?.projectId === projectId);
        }
        return operations;
      }),
      map((operations) => {
        if (getOnlyLatest) {
          return operations[operations.length - 1];
        }
        return operations;
      }),
    );
  }

  setOperations(operations: OperationDto[]) {
    this.operations$.next(operations);
  }
}
