import { Injectable } from '@angular/core';

import { LocalStorageService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageWithTimelimitService<T extends string> {
  // TODO strictPropertyInitialization we need to delete and properly initalize this property
  removalTimeToken: T = undefined!;
  tokensToWork: T[] = [];

  constructor(private localStorageService: LocalStorageService) {}
  // TODO rebuild it for session storage

  init(removalTimeToken: T, time: number, tokensToWork: T[]): void {
    const expiratinRaw = this.localStorageService.getItem(removalTimeToken);
    const expiratin = JSON.parse(expiratinRaw);

    const exporationToSave = expiratin && Date.now() < expiratin ? expiratin : Date.now() + time;
    this.localStorageService.setItem(removalTimeToken, exporationToSave);
    this.removalTimeToken = removalTimeToken;
    this.tokensToWork = tokensToWork;
  }

  // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
  getItemCheckExpiration(token: T): string | null | undefined {
    const rowitem = this.localStorageService.getItem(token);
    const item = JSON.parse(rowitem);

    if (!item) {
      return;
    }
    if (Date.now() < item?.ttl) {
      return item.value;
    } else {
      this.clearAllRegistrationTokens();
      return null;
    }
  }

  setItemAndExpiration(key: T, value: string): void {
    const expiratinRaw = this.localStorageService.getItem(this.removalTimeToken);
    const expiratin = JSON.parse(expiratinRaw);
    if (!expiratin || Date.now() > expiratin) {
      this.clearAllRegistrationTokens();
      return;
    }

    const valueToSave = { ttl: expiratin, value };
    this.localStorageService.setItem(key, JSON.stringify(valueToSave));
  }

  clearAllRegistrationTokens(): void {
    this.localStorageService.removeItem(this.removalTimeToken);
    this.tokensToWork.forEach((token) => {
      this.localStorageService.removeItem(token);
    });
  }
}
