import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { UserDetails, UserRole, UsersApiService } from '@api-open';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
  currentUser$ = new BehaviorSubject<UserDetails>(null!);

  constructor(private usersApiService: UsersApiService) {}

  getCurrentUser(): Observable<UserDetails> {
    return this.currentUser$.asObservable().pipe(filter<UserDetails>(Boolean));
  }

  getUserRole(): UserRole | undefined {
    return this.currentUser$.value?.data?.role;
  }

  loadCurrentUser(): Observable<UserDetails> {
    return this.usersApiService.usersUserInfo().pipe(
      tap((userInfo) => {
        this.setCurrentUser(userInfo);
      }),
    );
  }

  updateUserInfo(): void {
    this.usersApiService.usersUserInfo().subscribe((userInfo) => {
      this.setCurrentUser(userInfo);
    });
  }

  private setCurrentUser(user: UserDetails): void {
    if (user) {
      const { data: { id, email, username, role } = {} } = user;
      Sentry.setUser({
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        id: id!,
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        email: email!,
        // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
        username: username!,
        role,
      });
    } else {
      Sentry.setUser(null);
    }

    this.currentUser$.next(user);
  }
}
