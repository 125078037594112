export const G_TAG_ID = 'G-XQ00B401FT';
export const TITLE_SUFFIX = ' – TAIKUN';

export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refresh_token';
export const SIDEBAR_OPEN = 'sidebar_open';
export const NOT_FOUND_URL = 'not-found';
export const SAVED_ORGANIZATION = 'saved_organization';

export const USERS_PAGINATION_SIZE = 'users_pagination_size';
export const ORGANIZATIONS_PAGINATION_SIZE = 'organizations_pagination_size';
export const PROJECTS_PAGINATION_SIZE = 'projects_pagination_size';
export const PROJECT_VIRTUALS_PAGINATION_SIZE = 'project_virtuals_pagination_size';
export const APPLICATION_PAGINATION_SIZE = 'applicaion_pagination_size';
export const PROJECT_QUOTAS_PAGINATION_SIZE = 'project_quotas_pagination_size';
export const PROJECT_TEMPLATE_PAGINATION_SIZE = 'project_template_pagination_size';
export const SERVERS_PAGINATION_SIZE = 'servers_pagination_size';
export const FLAVOR_INFO_PAGINATION_SIZE = 'flavor_info_pagination_size';
export const AUDIT_LOG_PAGINATION_SIZE = 'audit_log_pagination_size';
export const BILLING_PAGINATION_SIZE = 'billing_pagination_size';
export const PROMETHEUS_BILLING_SUMMARY_PAGINATION_SIZE = 'prometheus_billing_summary_pagination_size';
export const FLAVOR_PROJECT_BOUNDS_PAGINATION_SIZE = 'flavor_project_bounds_pagination_size';
export const CLOUD_CREDENTIALS_PAGINATION_SIZE = 'cloud_credentials_pagination_size';
export const BACKUP_CREDENTIALS_PAGINATION_SIZE = 'backup_credentials_pagination_size';
export const ACCESS_PROFILES_PAGINATION_SIZE = 'access_profiles_pagination_size';
export const PUBLIC_REPOSITORY_PAGINATION_SIZE = 'public_repository_pagination_size';
export const DAEMON_SET_PAGINATION_SIZE = 'daemon_set_pagination_size';
export const DEPLOYMENT_LIST_PAGINATION_SIZE = 'deployment_list_pagination_size';
export const PERSISTENT_VOLUME_PAGINATION_SIZE = 'persistent_volume_pagination_size';
export const CONFIG_MAP_PAGINATION_SIZE = 'config_map_pagination_size';
export const SECRET_PAGINATION_SIZE = 'secret_pagination_size';
export const STS_PAGINATION_SIZE = 'sts_pagination_size';
export const SERVICE_PAGINATION_SIZE = 'service_pagination_size';
export const POD_LIST_PAGINATION_SIZE = 'podlist_pagination_size';
export const CRONJOB_PAGINATION_SIZE = 'cronjob_pagination_size';
export const CRD_PAGINATION_SIZE = 'crd_pagination_size';
export const PARTNERS_PAGINATION_SIZE = 'partners_pagination_size';
export const BACKUP_POLICY_PAGINATION_SIZE = 'backup_policy_pagination_size';
export const KUBERNETES_EVENTS_PAGINATION_SIZE = 'kubernetes_events_pagination_size';
export const KUBERNETES_ALERTS_PAGINATION_SIZE = 'kubernetes_alerts_pagination_size';
export const KUBERNETES_PROFILES_PAGINATION_SIZE = 'kubernetes_profiles_pagination_size';
export const INGRESS_PAGINATION_SIZE = 'ingress_pagination_size';
export const KUBE_CONFIG_PAGINATION_SIZE = 'kube_config_pagination_size';
export const SLACK_CONFIG_PAGINATION_SIZE = 'slack_config_pagination_size';
export const SHOWBACK_RULES_PAGINATION_SIZE = 'showback_rules_pagination_size';
export const SHOWBACK_CREDENTIALS_PAGINATION_SIZE = 'showback_credentials_pagination_size';
export const DOCUMENTATION_PAGINATION_SIZE = 'documentation_pagination_size';
export const ALERTING_PROFILES_PAGINATION_SIZE = 'alerting_profiles_pagination_size';
export const ALERTS_PAGINATION_SIZE = 'alerts_pagination_size';
export const TAIKUN_REQUEST_PAGINATION_SIZE = 'taikun_request_pagination_size';
export const SEARCH_DATA_SOURCES = 'search_data_sources';
export const PAYMENT_PAGINATION_SIZE = 'payment_pagination_size';
export const POD_DISRUPTION_PAGINATION_SIZE = 'pod_disruption_pagination_size';
export const BACKUP_POLICY_SCHEDULE_PAGINATION_SIZE = 'backup_policy_schedule_pagination_size';
export const BACKUP_POLICY_BACKUP_PAGINATION_SIZE = 'backup_policy_backup_pagination_size';
export const BACKUP_POLICY_RESTORE_PAGINATION_SIZE = 'backup_policy_restore_pagination_size';
export const OPA_PROFILES_PAGINATION_SIZE = 'opa_profiles_pagination_size';
export const IMAGES_PAGINATION_SIZE = 'images_pagination_size';
export const IMAGES_PROJECT_BOUNDS_PAGINATION_SIZE = 'images_project_bounds_pagination_size';
export const STANDALONE_PROFILES_PAGINATION_SIZE = 'standalone_profiles_pagination_size';
export const STANDALONE_VMS_PAGINATION_SIZE = 'standalone_vms_pagination_size';
export const PUBLIC_IMAGES_PAGINATION_SIZE = 'public_images_pagination_size';
export const BACKUP_POLICY_LOCATIONS_PAGINATION_SIZE = 'backup_policy_locations_pagination_size';
export const HELM_RELEASES_PAGINATION_SIZE = 'helm_releases_pagination_size';
export const NETWORK_POLICY_PAGINATION_SIZE = 'network_policy_pagination_size';
export const STORAGE_CLASS_PAGINATION_SIZE = 'storage_class_pagination_size';
export const AI_CREDENTIALS_PAGINATION_SIZE = 'ai_credentials_pagination_size';
export const IS_DEMO_MODE_ENABLED = 'is_demo_mode_enabled';

export const LOGS_HISTORY = 'logs_history';

export const AWS_SELECTED_DEFAULT_OWNERS = 'aws_selected_default_owners';
export const AWS_SELECTED_CUSTOM_OWNERS = 'aws_selected_custom_owners';
export const AWS_DEFAULT_OWNERS = 'aws_default_owners';
export const AWS_CUSTOM_OWNERS = 'aws_custom_owners';
export const AWS_CREDENTIALS_PAGINATION_SIZE = 'aws_credentials_pagination_size';

export const ZEDEDA_CREDENTIALS_PAGINATION_SIZE = ' zededa_credentials_pagination_size';

export const AWS_OWNERS = 'aws_owners_v1';
