import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoginRedirectService {
  private loginRedirectUrl?: string;

  getLoginRedirectUrl(): string | undefined {
    return this.loginRedirectUrl;
  }

  setLoginRedirectUrl(url: string | undefined): void {
    this.loginRedirectUrl = url;
  }
}
